import { createApp , h} from "vue";
import { createPinia } from "pinia";
import LaravelPermissionToVueJS from 'laravel-permission-to-vuejs'

import App from "./App.vue";
import router from "./router";
//import '@assets/css/app.css'
import '@assets/css/animate.css'

import '@public/fonts/montserrat.css?family=Montserrat:wght@400;'
import '@public/fonts/clanpro.css?family=Clan Pro:wght@250;450;475;500;700;900;950s'
import '@public/fonts/agrandir.css?family=Agrandirs'
import '@assets/css/tailwind.css'


import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css' 

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css'; 

import tooltip from "@/assets/js/tooltip.js";
import "@/assets/sass/css/tooltip.css";

import imageSrcFallback from "./directives/image-src-fallback.js";


import VueSocials from 'vue-socials';


import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


const pinia = createPinia()

const app = createApp(App);
app.use(LaravelPermissionToVueJS);
app.use(router);
app.use(pinia);


// Set the components prop default to return our fresh components 
/* vSelect.props.components.default = () => ({
  Deselect: {
    render: () => h('span', '❌'),
  },
  OpenIndicator: {
    render: () => h('span', '🔽'),
  },
});
 */


app.component("v-select", vSelect);
app.component('VueDatePicker', VueDatePicker);

app.use(VueSweetalert2);
app.directive("src-fallback", imageSrcFallback);
app.directive("tooltip", tooltip); 
app.use(VueSocials)



const optionsToast = {
    draggable: false
};

app.use(Toast, optionsToast);

import VueProgressBar from "@aacassandra/vue3-progressbar";

const progressbarOptions = {
  color: "#2196F3",
  failedColor: "#FF0000",
  thickness: "4px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 500,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
  autoFinish: false,
};
app.use(VueProgressBar, progressbarOptions);
app.mount("#app");

export default app;