import ImageNotFound from '../assets/images/NotFound.jpg';
//import Loading from "../assets/loading.gif";

export default {
    mounted(el, binding) {
        try {
            const { value } = binding;
            const loader = null;
            let fallBackImage = ImageNotFound;
            const img = new Image();
            let loading = loader;
            let error = fallBackImage;
            let original = el.src;
            if (typeof value === 'string') {
                loading = value;
                error = value;
            }
            if (value instanceof Object) {
                loading = value.imageLoader || loader || null;
                error = value.fallBackImage || fallBackImage;
            }
            img.src = original;
            el.src = loading;
            img.onload = () => {
                el.src = original;
            };
            img.onerror = () => {
                el.src = error;
            };
    } catch (e) {
            console.log(e)
        }
    }
}
