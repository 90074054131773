import { nextTick } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import api from "@helper/axios";
import { useGlobal } from "../store/global";

const Inicio = () => import("@views/Main/Inicio.vue");
const Descubrenos = () => import("@views/Main/Descubrenos.vue");
const Donaciones = () => import("@views/Main/Donaciones.vue");

const DonacionesMedicamentos = () => import("@views/Main/RegistroDonaMedicamentos.vue");
const DonacionesBuscar = () => import("@views/Main/RegistroBuscaMedicamento.vue");

const Cuidadores = () => import("@views/Main/Cuidadores.vue");
const Informate = () => import("@views/Main/Informate.vue");
const InformateDetalle = () => import("@views/Main/InformateDetalle.vue");
const Registro = () => import("@views/Main/Registro.vue");
const ForgotPassword = () => import("@views/Main/ForgotPassword.vue");
const Login = () => import("@views/Main/Login.vue");
const PoliticaPrivacidad = () => import("@views/Main/PoliticaPrivacidad.vue");
const Inspiracion = () => import("@views/Main/Inspiracion.vue");
const Paciente = () => import("@views/Main/Paciente.vue");
const Bienestar = () => import("@views/Main/Bienestar.vue");
const ArteTerapia = () => import("@views/Main/ArteTerapia.vue");
const AutoCuidado = () => import("@views/Main/AutoCuidado.vue");
const Comunicarte = () => import("@views/Main/Comunicarte.vue");
const Construccion  = () => import("@views/Construccion.vue");

const Investigacion = () => import("@views/Main/Investigacion.vue");
const Salud = () => import("@views/Main/Salud.vue");

const Page404 = () => import("@views/Pages/error/404.vue");
const Page500 = () => import("@views/Pages/error/500.vue");
const PageMaintenance = () => import("@views/Pages/error/maintenance.vue");


const Dashboard  = () => import("@views/Home/Inicio.vue");
const Usuario  = () => import("@views/Usuarios/Usuario.vue");
const UsuarioAdd  = () => import("@views/Usuarios/UsuarioAdd.vue");

const Publicaciones  = () => import("@views/Publicaciones/Publicaciones.vue");
const PublicacionesAdd  = () => import("@views/Publicaciones/PublicacionesAdd.vue");



import { useLoggedUser } from "@store/user.js"
var appname = " - Zuperarte ";

const routes = [
  // Routes
  {
    path: "/",
    name: "Construccion",
    component: Construccion,
    meta: { title: "página web en construcción" + appname }
  },
  
  {
    path: "/inicio",
    name: "Inicio",
    component: Inicio,
    meta: { title: "Inicio " + appname },
  },
   // layout/error
  // default page 404
  {
    path: "/:pathMatch(.*)*",
    name: "Page404",
    component: Page404,
    meta: { title: "404" + appname, },
  },
  {
    path: "/500",
    name: "Page500",
    component: Page500,
    meta: { title: "Error" + appname, },
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: PageMaintenance,
    meta: {
      title: "Mantenimiento" + appname },
  },
  {
    path: "/descubrenos",
    name: "Descubrenos",
    component: Descubrenos,
    meta: { title: "Descúbrenos  " + appname },
  },
  {
    path: "/donaciones",
    name: "Donaciones",
    component: Donaciones,
    meta: { title: "Donaciones " + appname },
  },

  {
    path: "/buscar_medicamentos",
    name: "DonacionesBuscar",
    component: DonacionesBuscar,
    meta: { title: "Buscar Medicamento" + appname },
  },
  {
    path: "/medicamentos",
    name: "DonacionesMedicamentos",
    component: DonacionesMedicamentos,
    meta: { title: "Medicamentos " + appname },
  },
 {
    path: "/cuidadores",
    name: "Cuidadores",
    component: Cuidadores,
    meta: { title: "Cuidadores " + appname },
  },
  {
    path: "/informate",
    name: "Informate",
    component: Informate,
    meta: { title: "Informate " + appname },
  },
  {
    path: "/:id:/:slug",
    name: "InformateDetalle",
    component: InformateDetalle,
    params: true,
    meta: { title: "Informate " + appname },
  },
  {
    path: "/investigacion",
    name: "Investigacion",
    component: Investigacion,
    meta: { title: " Investigación  " + appname },
  },
  {
    path: "/salud",
    name: "Salud",
    component: Salud,
    meta: { title: "salud " + appname },
  },
   {
    path: "/inspiracion",
    name: "Inspiracion",
    component: Inspiracion,
    meta: { title: "Inspiracion " + appname },
  }, 
  {
    path: "/arte_terapia",
    name: "ArteTerapia",
    component: ArteTerapia,
    meta: { title: "ArteTerapia " + appname },
  }, 
  {
    path: "/auto_cuidado",
    name: "AutoCuidado",
    component: AutoCuidado,
    meta: { title: "AutoCuidado " + appname },
  }, 
  {
    path: "/paciente_activo",
    name: "Paciente",
    component: Paciente,
    meta: { title: "Paciente Activo " + appname },
  }, 
  {
    path: "/bienestar",
    name: "Bienestar",
    component: Bienestar,
    meta: { title: "Bienestar" + appname },
  }, 
  {
    path: "/comunicarte",
    name: "Comunicarte",
    component: Comunicarte,
    meta: { title: "Comunicarte" + appname },
  }, 
  {
    path: "/iniciar_session",
    name: "Login",
    component: Login,
    meta: { title: "Iniciar Session " + appname },
  },
  {
    path: "/recuperar_contrasena",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: { title: "Olvidé mi contraseña" + appname},
  },
  {
    path: "/registro",
    name: "Registro",
    component: Registro,
    meta: { title: "Registro " + appname },
  },
  {
    path: "/politica_de_privacidad",
    name: "PoliticaPrivacidad",
    component: PoliticaPrivacidad,
    meta: { title: "Política de privacidad" + appname },
  },
  {
    path: "/home",
    name: "Dashboard",
    component: Dashboard,
    meta: { title: "Inicio " +   appname },
  },
  {
    path: "/usuario",
    name: "Usuario",
    component: Usuario,
    meta: { title: "Usuarios " +   appname },
  },
  {
    path: "/usuario/agregar",
    name: "UsuarioAdd",
    component: UsuarioAdd,
    meta: { title: "Usuario Registrar " + appname },
  },
  {
    path: "/publicacion",
    name: "Publicaciones",
    component: Publicaciones,
    meta: { title: "Publicaciones " +   appname },
  },
  {
    path: "/publicacion/agregar",
    name: "PublicacionesAdd",
    component: PublicacionesAdd,
    meta: { title: "Publicaciones Registrar " + appname },
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path != from.path) {
       return { top: 0 };
    }
 },
  linkExactActiveClass: "exact-active",
});

const getUser = async () => {
  let user = await api.get('/user')
  .then((res) => {
      //console.log(res);
      if(res.status != 200){
          global.loading = false
      }
      if(res.status == 200){
          const loggedUser = useLoggedUser();
          loggedUser.setLoggedUser(res.data);
          const global = useGlobal();
          global.loading = false;
      }
  })
  .catch((err) => {
      const global = useGlobal()
      global.loading = false
      global.setNetworkError(true)
  });
}

router.beforeEach(async (to, from, next) => {
  window.progress.start();

  const loggedUser = useLoggedUser();


  //Se agregan las pagina que no requieren de incio  de sesssion
const paginas =['Login','ForgotPassword','Construccion','Inicio',
'Descubrenos','Donaciones','Cuidadores','Informate','InformateDetalle','Investigacion','Salud','Inspiracion','ArteTerapia','AutoCuidado','Paciente','Bienestar','Comunicarte','Registro',
'Page404','Page500','PageMaintenance','PoliticaPrivacidad','DonacionesBuscar','DonacionesMedicamentos'];

   if (!loggedUser.loggedUser && !paginas.includes(to.name)) {
      await getUser();
  } 

  if ( !paginas.includes(to.name) && !loggedUser.loggedUser ) {
      next({ name: 'Login' });
  } else {
      if ( to.name == 'Login' && loggedUser.loggedUser ) {
          next({ name: 'Dashboard' });
      } else {
          document.title = to.meta.title;
          next();
      }
  }
});

router.afterEach(async (to, from) => {
  window.progress.finish();

  await nextTick();

  let preserveSateKeys = ['back', 'current', 'forward', 'replaced', 'position', 'scroll'];
  let stateKeys = Object.keys(window.history.state);
  for(let key of stateKeys){
      if(!preserveSateKeys.includes(key)){
          delete window.history.state[key];
      }
  }
});

router.onError(() => {
  window.progress.fail();
});

export default router;
