import router from '@/router/index.js';
import { useLoggedUser } from '@/store/user.js';
import { useGlobal } from '@/store/global';
import axios from 'axios';
import axiosRetry from 'axios-retry';

export const api = axios.create({
    baseURL: '/api',
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});


axiosRetry(api, {
    retries: 30,
    retryDelay: (retryCount) => {
        return retryCount * 1500; // time interval between retries
    },
    retryCondition(error) {
        if(error.code == "ERR_NETWORK" || error.code == "ECONNABORTED" || error.response.status > 500){
            return true;
        }else{
            return false;
        }
     },
     onRetry: (retryCount, error, requestConfig) => {
       console.log(`Reintentando: `, retryCount);
       if(retryCount == 30){
        if(error.code == "ERR_NETWORK"){
            const global = useGlobal()
            global.setNetworkError("ERR_NETWORK")
        }
        if(error.response?.status >= 500){
            const global = useGlobal()
            global.setNetworkError(500)
        }
       }
     },
 });

api.interceptors.response.use((response) => response, error => {
    if (error.response?.status === 401 && !['/ingresar'].includes(window.location.pathname)) {
        const loggedUser = useLoggedUser();
        loggedUser.setLoggedUser(null);

        //router.push("/ingresar");
    }else if(error.response?.status == 500){
        const global = useGlobal()
        global.setNetworkError(500)
    }
    return error;
}, function(error) {
    //window.progress.fail();
});



export default api;
