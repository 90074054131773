import { defineStore } from "pinia";

export const useGlobal = defineStore('global', {
  state: () => ({
    loading: true,
    networkError: false,
    apiUrl: import.meta.env.VITE_API_URL,
    baseUrl: import.meta.env.VITE_BASE_URL,
  }),
  getters: {
    getUrl(state) {
      return state.apiUrl;
    },
    getBaseUrl(state) {
      return state.baseUrl;
    },
  },
  actions: {
    setLoading($value) {
        this.loading = $value
    },
    setNetworkError($value) {
        this.networkError = $value
    }
  },
});
