import { defineStore } from "pinia";

export const useLoggedUser = defineStore('user', {
  state: () => ({
    loggedUser: null
  }),

  actions: {
    setLoggedUser($user) {
      this.loggedUser = $user
    },
    getLoggedUser() {
      return this.loggedUser
    }
  }
});
